export const TYPE = {
  YES: 'YA',
  NO: 'TIDAK',
};

export const ERROR_TYPE = {
  name: undefined,
  email: undefined,
  phone: undefined,
  address: undefined,
};

export const INPUT_COMMON_PROPS = {
  isRequired: true,
  size: 'sm',
  type: 'text',
  variant: 'outline',
  colorScheme: 'blackAlpha',
  backgroundColor: 'bgSecondary',
  color: 'secondaryColorText',
  fontSize: 'md',
  borderRadius: '0',
  fontFamily: 'body',
  _placeholder: { color: 'secondaryColorText' },
  _focus: { borderColor: 'secondaryColorText' },
};

export const SELECT_COMMON_PROPS = {
  borderRadius: '0',
  size: 'sm',
  fontSize: 'md',
  variant: 'outline',
  backgroundColor: 'bgSecondary',
  color: 'secondaryColorText',
  fontFamily: 'body',
  _focus: { borderColor: 'secondaryColorText' },
};

export const FORM_LABEL_PROPS = {
  ontSize: 'sm',
  color: 'mainColorText',
  margin: '8px 0',
  fontFamily: 'body',
};